
    @import '../assets/scss/series.scss';

    img.product-img {
        max-height: 340px;
        @media (min-width: 768px) {
            max-height: unset;
            /*position: absolute;*/
            /*padding-top: 100px!important;*/
        }
    }

    .series-banner {
        height: unset;
        margin-top: 0;
        @media (min-width: 768px) {
            height: 520px;
        }
    }

    .series-banner .details-wrapper h1 {
        font-size: 1.3rem;
        @media (min-width: 576px) {
            font-size: 1.5rem;
        }
        @media (min-width: 768px) {
            font-size: 1.8rem;
            margin-bottom: 35px!important;
        }
    }

    .details-wrapper {
        @media (min-width: 768px) {
            padding-top: 90px!important;
            padding-bottom: 0!important;
        }
    }

    #product-details {
        @media (min-width: 768px) {
            padding-bottom: 0;
        }
    }

    #becomepartner {
        @media (min-width: 768px) {
            padding-top: 0!important;
        }
    }

    .section-title {
        @media (min-width: 768px) {
            margin-bottom: 80px!important;
        }
    }

    img.square-img {
        @media (min-width: 768px) {
            max-height: 520px;
        }
    }

    img.rectangle-img {
        @media (min-width: 768px) {
            position: absolute;
            top: 86px;
        }
    }

