/* SECTION - Series Banner*/
.series-banner {
  height: 520px;
  overflow: hidden;
  margin-top: 6px;
  background: #f1f1f1;
  .series-image {
    position: relative;
    img {
      padding-top: 100px;
      position: absolute;
    }
  }
  .details-wrapper {
    height: 100%;
    padding-top: 90px;
    h1 {
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 35px;
    }
    .series-color {
      margin-bottom: 20px;
      .color {
        width: 28px;
        height: 28px;
        display: inline-block;
        border-radius: 50%;
        border: 3px solid white;
        background: red;
        &.silver {
          background: #e1e1e1;
        }
        &.green {
          background: #bdde55;
        }
        &.red {
          background: #fd4e4c;
        }
        &.grey {
          background: #626262;
        }
        &.blue {
          background: #3671d7;
        }
      }
    }
    .product-properties {
      color: #000;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 6px;
    }
    .recommended-price {
      font-size: 22px;
      font-weight: 500;
      color: #000;
    }
    .call-to-action {
      margin-top: 45px;
    }
    .btn {
      width: 240px;
      font-size: 14px;
      padding: 12px 15px;
      border-radius: 5px;
      &.btn-black {
        color: #fff;
        background: #000;
      }

      &.btn-white {
        color: #000;
        background: #fff;
      }
    }

  }
}